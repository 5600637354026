import React from "react";
import Card from "react-bootstrap/Card";

import prayer from "../../assets/img/misc/prayer.png";

const Prayer = () => {
  return (
    <Card border="secondary" className="shadow bg-white rounded">
      <Card.Img style={{ height: "200px" }} variant="top" src={prayer} />
      <Card.Body>
        <Card.Title>Prayer</Card.Title>
        <Card.Text>
          <p>
            <b>1 Thes. 5:17-18</b> Rejoice always, pray without ceasing, in
            everything give thanks; for this is the will of God in Christ Jesus
            for you.
          </p>
          <p>
            <b>Luke 11: 1</b> Now it came to pass, as He was praying in a
            certain place, when He ceased, that one of His disciples said to
            Him, “Lord, teach us to pray, as John also taught his disciples
          </p>
          <p>
            In every worship service, you will be led in multiple prayers by
            Godly men.
          </p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Prayer;
