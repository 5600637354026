import React from "react";
import Card from "react-bootstrap/Card";

import giving from "../../assets/img/misc/giving.gif";

const Giving = () => {
  return (
    <Card border="secondary" className="shadow  bg-white rounded">
      <Card.Img variant="top" style={{ height: "200px" }} src={giving} />
      <Card.Body>
        <Card.Title>Giving</Card.Title>
        <Card.Text>
          <p>
            <b>1 Cor. 16:1-2</b> Now concerning the collection for the saints,
            as I have given orders to the churches of Galatia, so you must do
            also, On the first day of the week let each one of you lay something
            aside, storing up as he may prosper, that there be no collections
            when I come.
          </p>

          <p>
            The Bible teaches that it is every Christians duty to support their
            local congregations. This allows the church to minister and spread
            the Gospel throughout the world.
          </p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Giving;
