import React, { useState } from "react";
import { useHistory } from "react-router-dom";
//import { useForm } from "react-hook-form";
import { Formik } from "formik";
import * as Yup from "yup";

// react-bootstrap components
import { Button, Card, Form, Container, Col } from "react-bootstrap";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid Email")
    .required("Please Enter your email."),
});

function ForgotPasswordPage() {
  const [cardClasses, setCardClasses] = React.useState("card-hidden");
  const history = useHistory();
  const [message, setMessage] = useState();

  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });

  async function onSubmit(data, e) {
    setMessage({
      data: "Reset Password email is being sent",
      type: "alert-warning",
    });

    const response = await fetch("/users/forgot_password", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    if (response.status === 200) {
      const res = await response.json();

      setMessage({
        data:
          "An email has been sent to  " +
          res.user.primary_email.email +
          "! redirecting....",
        type: "alert-success",
      });

      setTimeout(() => {
        history.push("/main/home");
      }, 2000);
      e.target.reset();
      return;
    }

    const ret = await response.json();

    setMessage({
      data: ret.error,
      type: "alert-danger",
    });
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        email: "",
      }}
      onSubmit={(values) => onSubmit(values)}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <>
          <div
            className="full-page section-image"
            data-color="black"
            data-image={require("../../assets/img/misc/building.jpg").default}
          >
            <div className="content d-flex align-items-center p-0">
              <Container>
                <Col className="mx-auto" lg="4" md="8">
                  {message && (
                    <div
                      className={`alert fade show d-flex ${message.type}`}
                      role="alert"
                    >
                      {message.data}
                      <span
                        aria-hidden="true"
                        className="ml-auto cursor-pointer"
                        onClick={() => setMessage(null)}
                      >
                        &times;
                      </span>
                    </div>
                  )}
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card className={"card-login " + cardClasses}>
                      <Card.Header>
                        <h3 className="header text-center">Reset Password</h3>
                      </Card.Header>
                      <Card.Body>
                        <Form.Group controlId="email">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="Enter Email Address"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={touched.email && !errors.email}
                          />
                          {errors.email && touched.email ? (
                            <div className="text-danger">{errors.email}</div>
                          ) : null}
                        </Form.Group>
                      </Card.Body>
                      <Card.Footer className="ml-auto mr-auto">
                        <Button
                          className="btn-wd"
                          type="submit"
                          variant="warning"
                        >
                          Send Link
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Form>
                </Col>
              </Container>
            </div>
            <div
              className="full-page-background"
              style={{
                backgroundImage:
                  "url(" +
                  require("../../assets/img/misc/building.jpg").default +
                  ")",
              }}
            />
          </div>
        </>
      )}
    </Formik>
  ); // end of the return
}

export default ForgotPasswordPage;
