import React from "react";
import { useHistory } from "react-router-dom";

const Logout = () => {
  async function DoLogout() {
    console.log("DoLogout");
    const history = useHistory();

    await fetch("/users/logout", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    });

    localStorage.clear();
    history.push("/main/home");
  }

  DoLogout();

  return <></>;
};

export default Logout;
