import React from "react";
import Card from "react-bootstrap/Card";

import preaching from "../../assets/img/misc/preacher.png";

const Preaching = () => {
  return (
    <Card border="secondary" className="shadow  bg-white rounded">
      <Card.Img variant="top" style={{ height: "200px" }} src={preaching} />
      <Card.Body>
        <Card.Title>Preaching</Card.Title>
        <Card.Text>
          <p>
            <b>Acts 8:4</b> Therefore those who were scattered went everywhere
            preaching the word.
          </p>

          <p>
            The sermons at the West Hobbs Street Church of Christ will ALWAYS be
            based solely on the Bible! Bro. Jameson Steward is our full-time
            pulpit minister, and he is fully qualified to bring us the Word of
            God. Our members are encouraged to study their Bibles, and prove to
            themselves that the sermons presented are truly from God’s word.{" "}
          </p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Preaching;
