import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

import graphic from "../../assets/img/misc/livestream.png";

const LiveStream = () => {
  return (
    <Card border="secondary" className="shadow  mb-3 bg-white rounded">
      <Card.Img variant="top" src={graphic} />
      <Card.Body>
        <Card.Title>Live Streaming</Card.Title>
        <Card.Text>
          Our Live Streaming services are as follow (all times are Central Time
          Zone)
          <ul>
            <li> Sunday Morning Adult Bible Class (9:00 am)</li>
            <li> Sunday Morning Worship (10:00 am)</li>
            <li> Sunday Evening Worship (5:00 pm)</li>
            <li> Wednesday Evening Adult Bible Class (7:00 pm)</li>
          </ul>
          <Button
            href="https://app.omnia.church/channels/whs-church-of-christ"
            target="_blank"
          >
            Click here to watch our LiveStream
          </Button>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default LiveStream;
