import React from "react";
import Card from "react-bootstrap/Card";

import vbs from "../../assets/img/vbs/vbs_2024.png";
import { Table } from "react-bootstrap";

const vbs2024 = () => {
  return (
    <Card border="secondary" className="shadow  mb-3 bg-white rounded">
      <Card.Img variant="top" src={vbs} />
      <Card.Body>
        <Card.Title>VBS 2024 - The Battle Belongs to the Lord</Card.Title>
        <Card.Text>
          There will be classes for all ages, including adults. Each evening
          there will be skits and snacks.
          <Table className="table-hover table-striped w-full">
            <tr>
              <td>Monday (June 3rd)</td>
              <td>Walls of Jericho</td>
            </tr>
            <tr>
              <td>Tuesday (June 4th)</td>
              <td>David & Goliath</td>
            </tr>
            <tr>
              <td>Wednesday (June 5th)</td>
              <td>Mount Carmel Showdown</td>
            </tr>
            <tr>
              <td>Thursday (June 6th)</td>
              <td>The Christian Armor</td>
            </tr>
          </Table>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default vbs2024;
