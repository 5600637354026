import React from "react";
const { utcToZonedTime, format } = require("date-fns-tz");

const ShortRow = (speaker) => {
  return (
    <>
      <tr>
        <td>
          {format(
            new utcToZonedTime(speaker.date, "America/Chicago"),
            "LLLL do"
          )}
        </td>
        <td>{speaker.speaker}</td>
      </tr>
    </>
  );
};
export default ShortRow;
