import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
//import "./assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "./assets/css/master.css";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/light-bootstrap-dashboard-pro-react.scss?v=2.0.0";
import "./assets/css/master.css";

import Main from "./layouts/Main";
import Auth from "./layouts/Auth";
import Admin from "./layouts/Admin";

const token = localStorage.getItem("token");
let access = [];
if (token === null) {
  localStorage.clear();
} else {
  access = localStorage.getItem("access").split("|");
}

const hasAdmin = access.length > 0 ? true : false;

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/main" render={(props) => <Main {...props} />} />
      <Route path="/auth" render={(props) => <Auth {...props} />} />
      {hasAdmin && (
        <Route path="/admin" render={(props) => <Admin {...props} />} />
      )}
      <Redirect from="/" to="/main/home" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
