import React from "react";
import Card from "react-bootstrap/Card";
import singing from "../../assets/img/misc/singing.jpg";

const Singing = () => {
  return (
    <Card border="secondary" className="shadow bg-white rounded">
      <Card.Img variant="top" style={{ height: "200px" }} src={singing} />
      <Card.Body>
        <Card.Title>Singing</Card.Title>
        <Card.Text>
          <p>
            <b>Eph 5:19</b> speaking to one another in psalms and hymns and
            spiritual songs, singing and making melody in your heart to the Lord
          </p>

          <p>
            <b>1 Cor 14:15</b> What is the conclusion then? I will pray with the
            spirit, and I will also pray with the understanding. I will sing
            with the spirit, and I will also sing with the understanding.
          </p>

          <p>
            In every worship service there will be acapella, congregational
            singing. Our song service is led by men without the use of any
            instruments.
          </p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Singing;
