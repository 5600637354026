import React, { useState, useEffect } from "react";
import Collapse from "react-bootstrap/Collapse";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

const BlogItem = (blog) => {
  // destruct the object coming in
  let { title, author, publish_date, body, searchTerm } = blog;

  // if the searchTerm is present then decorate the text
  const divStyle = "<span style='background:yellow'>";

  if (searchTerm.length) {
    let re = new RegExp(searchTerm, "ig");
    body = body.replace(re, divStyle + searchTerm + "</span>");
  }

  // determine if the user has blog access for editing and adding
  const allowEdit =
    localStorage.getItem("access") !== null
      ? localStorage.getItem("access").split("|").includes("blog")
      : false;

  // find the first paragraph
  let p1 = body.indexOf("</p>");
  let firstParagraph = "";
  let secondParagraph = "";
  let secondParagraphCount = 0;

  if (p1 === -1) {
    firstParagraph = body;
  } else {
    firstParagraph = body.substr(0, p1 + 4);
    let p2 = body.indexOf("<p>", p1);
    secondParagraph = body.substr(p2);
    let words = secondParagraph.split(" ");
    secondParagraphCount = words.length;
  }

  const [open, setOpen] = useState(false);
  const [buttonText, setButtonText] = useState(
    "Keep Reading - " + secondParagraphCount + " WORDS"
  );

  return (
    <Container fluid className="shadow p-3 mb-3 bg-white rounded">
      <div className="blogItem">
        <h3>{title}</h3>
        <h5>
          {author} -{" "}
          <span className="text-muted">
            {new Intl.DateTimeFormat("en-US", {
              month: "long",
              day: "2-digit",
              year: "numeric",
            }).format(new Date(publish_date))}
          </span>
        </h5>
        <div dangerouslySetInnerHTML={{ __html: firstParagraph }}></div>
        <Collapse in={open}>
          <div
            id="collapsePart"
            dangerouslySetInnerHTML={{ __html: secondParagraph }}
          ></div>
        </Collapse>
        {secondParagraphCount > 0 && (
          <Button
            variant="light"
            onClick={() => {
              setOpen(!open);
              if (open) {
                setButtonText(
                  "Keep Reading - " + secondParagraphCount + " WORDS"
                );
              } else {
                setButtonText("Show Less");
              }
            }}
            aria-controls="collapsePart"
            aria-expanded={open}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </Container>
  );
};

export default BlogItem;
