import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { Col, Row, Card, Form, Button, Modal } from "react-bootstrap";
import blogStore from "../../stores/blog";

const schema = Yup.object().shape({
  title: Yup.string()
    .min(3, "The title should contain at least 3 characters")
    .required("Title is required"),
  author: Yup.string()
    .min(3, "The author should contain at least 3 characters")
    .required("Author is required"),
  body: Yup.string()
    .min(3, "The body should contain at least 3 characters")
    .required("Body is required"),
  publish: Yup.boolean().default(true),
  sticky: Yup.boolean().default(false),
  publish_date: Yup.date().default(function () {
    return new Date();
  }),
  expire_date: Yup.date().default(function () {
    return new Date("2061-04-10");
  }),
});

function BlogEditItem({ blog }) {
  console.log(BlogEditItem);
  const history = useHistory();
  const [message, setMessage] = useState();

  const handleClose = () => {};

  async function onSubmit(data, e) {
    console.log("onSubmit", data);
  }

  return (
    <Modal
      {...blog}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Edit Blog</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          validationSchema={schema}
          initialValues={{
            title: blog.title,
            author: blog.author,
            body: blog.body,
            publish: blog.publish,
            sticky: blog.sticky,
            publish_date: blog.publish_date,
            expire_date: blog.expire_date,
          }}
          onSubmit={(values) => onSubmit(values)}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <>
              <div className="content d-flex align-items-center p-0">
                <Col className="mx-auto" lg="4" md="8">
                  {message && (
                    <div
                      className={`alert fade show d-flex ${message.type}`}
                      role="alert"
                    >
                      {message.data}
                      <span
                        aria-hidden="true"
                        className="ml-auto cursor-pointer"
                        onClick={() => setMessage(null)}
                      >
                        &times;
                      </span>
                    </div>
                  )}
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                      <Col sm="2">
                        <Form.Label>Title</Form.Label>
                      </Col>
                      <Col sm="7">
                        <Form.Group controlId="title">
                          <Form.Control
                            type="text"
                            name="title"
                            placeholder="Enter the title of the blog"
                            value={values.title}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={touched.title && !errors.title}
                          />
                          {errors.title && touched.title ? (
                            <div className="text-danger">{errors.title}</div>
                          ) : null}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </div>
            </>
          )}
        </Formik>
      </Modal.Body>
      <Modal.Footer className="justify-content-end">
        <span>
          <Button onClick={handleClose} variant="secondary">
            Close
          </Button>
        </span>
        <span>
          <Button onClick={handleClose} variant="primary">
            Save changes
          </Button>
        </span>
      </Modal.Footer>
    </Modal>
  );
}

export default BlogEditItem;
