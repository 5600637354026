import React from 'react';

const JamesonText = () => {

return (
    <>
        <p>Bro. Steward became the full-time pulpit minister on February 14, 2021. He received a Bachelor of
            Business Administration from the University of Mississippi in 2015 and graduated from the
            Memphis School of Preaching in 2018. While studying at the Memphis School of Preaching he served
            as a Preacher-Intern at the Nesbit Church of Christ in Nesbit, MS. In June of 2018 he became the
            full-time pulpit minister at the Smithville Church of Christ in Smithville, MS.</p>
        <p>At Smithville Church of Christ, Jameson served as editor of “The Community Messenger”, which in
            April reached 1 million people worldwide. He is also host of a podcast called “Asking God Why”.
            This podcast is designed to reach younger audiences who are searching for the truth.
            Additionally, Jameson has worked with the Gospel Broadcasting Network where he is a co-host of a
            show called “Finding Jesus Today” </p>
        <p>Jameson and his wife Ashley were married in June of 2013 at Ashley’s home congregation, the
            Verona Church of Christ. They met at the Maywood Christian Camp in Hamilton, Alabama. Both
            Jameson and Ashley were blessed to be raised by godly parents who raised them to be Christians.
            Ashley has a degree in Elementary Education from the University of Mississippi and has taught
            kindergarten in Tupelo, MS. </p>
        <p>The Steward’s have three children, Nate, Casen and Ava Drew. As a family, they enjoy sports,
            spending time outside, visiting with family and reading. They work hard to make sure they spend
            quality time together as a family. They have already taken the boys to quite a few baseball,
            basketball and football games.</p>
    </>
)
}

export default JamesonText;