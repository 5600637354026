import { makeObservable, observable, action, computed, configure } from "mobx";
const axios = require("axios");

configure({ enforceActions: "never" });

class blogStore {
  blogData = {};
  allBlogData = [];
  searchTerm = "";
  tmpSearchTerm = "";
  pageNumber = 1;
  sortOrder = "";
  limit = 5;
  isLoading = false;
  isAllLoading = false;

  constructor(page, order, limit, search) {
    makeObservable(this, {
      blogData: observable,
      allBlogData: observable,
      searchTerm: observable,
      tmpSearchTerm: observable,
      pageNumber: observable,
      sortOrder: observable,
      limit: observable,

      getDisplayText: computed,
      getBlogDocs: computed,
      getAllBlogDocs: computed,

      setPageNumber: action,
      setSort: action,
      setLimit: action,
      setSearchTerm: action,
      setTmpSearchTerm: action,
      fetchBlog: action,
      fetchAllBlog: action,
    });
    this.fetchBlog(page, order, limit, search);
    this.fetchAllBlog();
  }

  async fetchBlog(page, order, limit, search) {
    this.isLoading = true;
    const query =
      "/blog?sort=" +
      order +
      "&limit=" +
      limit +
      "&page=" +
      page +
      "&search=" +
      search;
    try {
      const data = await axios.get(query);
      this.blogData = data.data;
      this.pageNumber = 1;
      this.isLoading = false;
    } catch (e) {
      console.log(e);
    }
  }

  async fetchAllBlog() {
    this.isAllLoading = true;
    const query = "/blog/all";
    try {
      const data = await axios.get(query);
      this.allBlogData = data.data;
      this.isLAllLoading = false;
    } catch (e) {
      console.log(e);
    }
  }

  get getDisplayText() {
    return (
      "Page " +
      this.blogData.page +
      " of " +
      this.blogData.totalPages +
      " (" +
      this.blogData.totalDocs +
      " Articles)"
    );
  }

  get getBlogDocs() {
    return this.blogData.docs;
  }

  get getAllBlogDocs() {
    return this.allBlogData;
  }

  setSearchTerm(term) {
    this.searchTerm = term;
    this.fetchBlog(
      this.pageNumber,
      this.sortOrder,
      this.limit,
      this.searchTerm
    );
  }

  setTmpSearchTerm(term) {
    this.tmpSearchTerm = term;
  }

  setPageNumber(pageNumber) {
    this.pageNumber = pageNumber;
    this.fetchBlog(
      this.pageNumber,
      this.sortOrder,
      this.limit,
      this.searchTerm
    );
  }

  setSort(sortOrder) {
    this.sort = sortOrder;
    this.fetchBlog(
      this.pageNumber,
      this.sortOrder,
      this.limit,
      this.searchTerm
    );
  }

  setLimit(limit) {
    this.limit = limit;
    this.fetchBlog(
      this.pageNumber,
      this.sortOrder,
      this.limit,
      this.searchTerm
    );
  }
}

export default new blogStore(1, "desc", 5, "");
