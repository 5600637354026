import React, { useState, useEffect } from "react";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import ElderCard from "./ElderCard";
import ElderText from "../../assets/txt/ElderText";

export default function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    await axios
      .get("/users?elders")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  if (loading) return "Loading...";
  if (error) return "Error!";
  return (
    <>
      <Row className="page_section">
        <Col className="col-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
          <h1>Our Elders</h1>
        </Col>
      </Row>
      <Row className="page_section">
        <ElderText />
      </Row>

      <Row>
        {data.map((elder) => {
          return (
            <Col className="col-12 col-sm-12 col-md-4">
              <ElderCard key={elder._id} {...elder}></ElderCard>
            </Col>
          );
        })}
      </Row>
    </>
  );
}
