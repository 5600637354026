import React from "react";

// core components
import { Container, Row, Col, Image } from "react-bootstrap";
import pic from "../../assets/img/misc/construction.jpg";

const UnderConstruction = () => {
  return (
    <Container fluid>
      <Row>
        <Col align="center">
          <h1>Under Construction </h1>
        </Col>
      </Row>
      <Row>
        <Col align="center">This feature is not available at this time.</Col>
      </Row>
      <Row>
        <Col align="center">
          <Image fluid width="500px" src={pic} />
        </Col>
      </Row>
      <Row>
        <Col align="center">
          <h2>Try again soon</h2>
        </Col>
      </Row>
    </Container>
  );
};

export default UnderConstruction;
