import React from "react";
import { Switch, Route } from "react-router-dom";

// core components

import MainNavbar from "../components/Navbars/MainNavbar";
import Banner from "../components/Global/Banner";
import MainPage from "../pages/main/MainPage";

// Dynamically create dashboard routes
import routes from "../routes.js";

function Main() {
  const getRoutes = (routes) => {
    const newRoutes = routes.map((prop, key) => {
      //            if (prop.collapse) {
      //                return getRoutes(prop.views);
      //            }
      if (prop.layout === "/main") {
        return (
          <Route
            path={prop.layout + prop.page}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    });

    newRoutes.push(<Route key="Not Found" component={MainPage} />);
    return newRoutes;
  };
  return (
    <>
      <div className="wrapper">
        <div className="home-panel">
          <MainNavbar />
          <Banner />
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>

          <div
            className="close-layer"
            onClick={() =>
              document.documentElement.classList.toggle("nav-open")
            }
          />
        </div>
      </div>
    </>
  );
}

export default Main;
