import React from 'react';

const WorshipText = () => {

return (
    <>
    <p>The West Hobbs Street Church of Christ is a congregation of God’s people who believe in loving God by doing His
        Will (John 14:15) and in loving each other in order that others may see that we follow Christ (John 13:34). We
        believe the Bible to be the very Word of God and thus authoritative in every aspect of our lives (2 Timothy
        3:16-17).</p>

    <p>Our worship services are according to the pattern revealed in the New Testament. On the Lord’s Day we sing (a
        cappella), pray, give as we have been prospered, have preaching and partake of the Lord’s Supper. We have
        classes for all ages on Sunday mornings and Wednesday nights. We also have a Sunday evening service each week.
        The church is together frequently for fellowship and work activities.</p>
    </>
)
}

export default WorshipText;