import React, { useState } from "react";
import { useHistory } from "react-router-dom";
//import { useForm } from "react-hook-form";
import { Formik } from "formik";
import * as Yup from "yup";

// react-bootstrap components
import { Button, Card, Form, Container, Col } from "react-bootstrap";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid Email")
    .required("Please Enter your email."),
  first_name: Yup.string()
    .min(3, "First Names must be at least 3 Characters")
    .required("Please enter your first name"),
  last_name: Yup.string()
    .min(3, "Last Names must be at least 3 Characters")
    .required("Please enter your last name"),
  password: Yup.string()
    .min(7, "Passwords must be at least 7 Characters")
    .required("Please enter your password"),
  passwordMatch: Yup.string()
    .required("Please enter your matching password")
    .min(7, "Passwords must be 7 characters")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

function RegisterPage() {
  const [cardClasses, setCardClasses] = React.useState("card-hidden");
  const history = useHistory();
  const [message, setMessage] = useState();

  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });

  async function onSubmit(data, e) {
    setMessage({
      data: "Registration is in progress...",
      type: "alert-warning",
    });
    const response = await fetch("/users", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });

    if (response.status === 201) {
      const res = await response.json();

      setMessage({
        data: "Welcome " + res.user.first_name + "! Your account is activated",
        type: "alert-success",
      });
      localStorage.setItem("token", res.token);
      localStorage.setItem("id", res.user._id);
      localStorage.setItem("first_name", res.user.first_name);
      localStorage.setItem("last_name", res.user.last_name);
      localStorage.setItem("access", res.user.access.join("|"));
      localStorage.setItem("role", res.user.role.toString());

      setTimeout(() => {
        history.push("/main/home");
      }, 2000);
      e.target.reset();
      return;
    }

    // if you made it here there was some type of error
    const ret = await response.json();

    // We will let this be the default error message
    let msg = "Something Went Wrong";

    // This is the most likely error that someone is trying to re register an
    // email address that already exists
    if (ret.code === 11000) {
      msg =
        "This email address is already registered. Go to the login screen to login";
    } else {
      msg = ret.message;
    }

    setMessage({
      data: msg,
      type: "alert-danger",
    });
  }

  return (
    <Formik
      validationSchema={schema}
      initialValues={{
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        passwordMatch: "",
      }}
      onSubmit={(values) => onSubmit(values)}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <>
          <div
            className="full-page section-image"
            data-color="black"
            data-image={require("../../assets/img/misc/building.jpg").default}
          >
            <div className="content d-flex align-items-center p-0">
              <Container>
                <Col className="mx-auto" lg="4" md="8">
                  {message && (
                    <div
                      className={`alert fade show d-flex ${message.type}`}
                      role="alert"
                    >
                      {message.data}
                      <span
                        aria-hidden="true"
                        className="ml-auto cursor-pointer"
                        onClick={() => setMessage(null)}
                      >
                        &times;
                      </span>
                    </div>
                  )}
                  <Form noValidate onSubmit={handleSubmit}>
                    <Card className={"card-login " + cardClasses}>
                      <Card.Header>
                        <h3 className="header text-center">Register</h3>
                      </Card.Header>
                      <Card.Body>
                        <Form.Group controlId="email">
                          <Form.Label>Email address</Form.Label>
                          <Form.Control
                            type="text"
                            name="email"
                            placeholder="Enter Email Address"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={touched.email && !errors.email}
                          />
                          {errors.email && touched.email ? (
                            <div className="text-danger">{errors.email}</div>
                          ) : null}
                        </Form.Group>

                        <Form.Group controlId="first_name">
                          <Form.Label>First Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="first_name"
                            placeholder="Enter your first name"
                            value={values.first_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={touched.first_name && !errors.first_name}
                          />
                          {errors.first_name && touched.first_name ? (
                            <div className="text-danger">
                              {errors.first_name}
                            </div>
                          ) : null}
                        </Form.Group>

                        <Form.Group controlId="last_name">
                          <Form.Label>Last Name</Form.Label>
                          <Form.Control
                            type="text"
                            name="last_name"
                            placeholder="Enter your last name"
                            value={values.last_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={touched.last_name && !errors.last_name}
                          />
                          {errors.last_name && touched.last_name ? (
                            <div className="text-danger">
                              {errors.last_name}
                            </div>
                          ) : null}
                        </Form.Group>

                        <Form.Group>
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="Enter Password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={touched.password && !errors.password}
                          />
                          {errors.password && touched.password ? (
                            <div className="text-danger">{errors.password}</div>
                          ) : null}
                        </Form.Group>

                        <Form.Group>
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="passwordMatch"
                            placeholder="Enter Password (again)"
                            value={values.passwordMatch}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isValid={
                              touched.passwordMatch && !errors.passwordMatch
                            }
                          />
                          {errors.passwordMatch && touched.passwordMatch ? (
                            <div className="text-danger">
                              {errors.passwordMatch}
                            </div>
                          ) : null}
                        </Form.Group>
                      </Card.Body>
                      <Card.Footer className="ml-auto mr-auto">
                        <Button
                          className="btn-wd"
                          type="submit"
                          variant="warning"
                        >
                          Register
                        </Button>
                      </Card.Footer>
                    </Card>
                  </Form>
                </Col>
              </Container>
            </div>
            <div
              className="full-page-background"
              style={{
                backgroundImage:
                  "url(" +
                  require("../../assets/img/misc/building.jpg").default +
                  ")",
              }}
            />
          </div>
        </>
      )}
    </Formik>
  ); // end of the return
}

export default RegisterPage;
