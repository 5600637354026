import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Image from "react-bootstrap/Image";
//import {Link} from 'react-router-dom';

import building from "../../assets/img/misc/building.jpg";
const Banner = () => {
  return (
    <Container fluid className="banner">
      <Row>
        <Col className="col-12 col-md-3">
          <Image src={building} style={{ maxHeight: "200px" }} />
        </Col>
        <Col className="col-12 col-md-6">
          <h1>West Hobbs Street Church of Christ</h1>
          <h2>Preaching the Never-Changing Christ to an Ever-Changing World</h2>
        </Col>
        <Col className="col-12 col-md-3">
          <table>
            <tbody>
              <tr>
                <td colSpan="2" className="text-center">
                  <b>SUNDAY</b>
                </td>
              </tr>
              <tr>
                <td className="text-right">
                  <b>Bible Class: </b>
                </td>
                <td>9:00 AM</td>
              </tr>
              <tr>
                <td className="text-right">
                  <b>AM Worship: </b>
                </td>
                <td>10:00 AM</td>
              </tr>
              <tr>
                <td className="text-right">
                  <b>PM Worship: </b>
                </td>
                <td>5:00 PM</td>
              </tr>
              <tr>
                <td colSpan="2" className="text-center">
                  <b>WEDNESDAY</b>
                </td>
              </tr>
              <tr>
                <td className="text-right">
                  <b>Bible Class: </b>
                </td>
                <td>7:00 PM</td>
              </tr>
            </tbody>
          </table>
        </Col>
      </Row>
      <Row
        style={{
          marginBottom: "5px",
          backgroundColor: "#055ecf",
          color: "white",
        }}
      >
        <Col className="col-6 col-md-3">
          <div className="text-center">P.O. Box 506</div>
        </Col>
        <Col className="col-6 col-md-3">
          <div className="text-center">1602 West Hobbs Street</div>
        </Col>
        <Col className="col-6 col-md-3">
          <div className="text-center">Athens, Alabama 35612</div>
        </Col>
        <Col className="col-6 col-md-3">
          <div className="text-center">(256) 232 - 4705</div>
        </Col>
      </Row>
    </Container>
  );
};

export default Banner;
