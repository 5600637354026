import React from "react";

import { Dropdown, Navbar, Nav, Container } from "react-bootstrap";

function MainNavBar() {
  const [collapseOpen, setCollapseOpen] = React.useState(false);

  let first_name = "";
  let access = [];
  let role = 0;

  const token = localStorage.getItem("token");
  if (localStorage.getItem("token") === null) {
    localStorage.clear();
  } else {
    first_name = localStorage.getItem("first_name");
    access = localStorage.getItem("access").split("|");
    role = Number(localStorage.getItem("role"));
  }

  let hasAdmin = false;
  if (access.length > 0) {
    // if it is just 1 make sure it's not null
    if (access.length === 1) {
      if (access[0].length > 0) {
        hasAdmin = true;
      }
    } else {
      hasAdmin = true;
    }
  }

  return (
    <>
      <a id="top" />
      <Container fluid>
        <Navbar expand="lg" fixed="top">
          <div className="navbar-wrapper">
            <Navbar.Brand href="/main/home">
              West Hobbs Street Church of Christ
            </Navbar.Brand>
          </div>
          <button
            className="navbar-toggler navbar-toggler-right border-0"
            type="button"
            onClick={() => setCollapseOpen(!collapseOpen)}
          >
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
            <span className="navbar-toggler-bar burger-lines"></span>
          </button>
          <Navbar.Collapse className="justify-content-left" in={collapseOpen}>
            <Nav navbar>
              <Nav.Link href="#ourworship">Our Worship</Nav.Link>
              <Nav.Link href="#pulpitminister">Pulpit Minister</Nav.Link>
              <Nav.Link href="#ourelders">Our Elders</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Collapse className="justify-content-end" in={collapseOpen}>
            <Nav navbar>
              {token === null && (
                <Nav.Link href="/auth/login">
                  <i className="nc-icon nc-single-02" />
                  &nbsp;&nbsp;Login
                </Nav.Link>
              )}
              {token !== null && (
                <Dropdown as={Nav.Item}>
                  <Dropdown.Toggle
                    as={Nav.Link}
                    id="dropdown-user"
                    variant="default"
                  >
                    <i className="nc-icon nc-single-02" />
                    &nbsp;&nbsp;{first_name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item href="/main/profile">Profile</Dropdown.Item>
                    {hasAdmin && (
                      <>
                        <div className="divider" />
                        <Dropdown.Item href="/admin/main">Admin</Dropdown.Item>
                      </>
                    )}

                    {role >= 2 && (
                      <Dropdown.Item href="/elder/main">Elder</Dropdown.Item>
                    )}

                    <div className="divider" />
                    <Dropdown.Item href="/auth/logout">Logout</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
    </>
  );
}

export default MainNavBar;
