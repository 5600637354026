import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";

// react-bootstrap components
import { Button, Card, Form, Container, Col } from "react-bootstrap";
const querystring = require("querystring");

const schema = Yup.object().shape({
  password: Yup.string()
    .min(7, "Passwords must be at least 7 Characters")
    .required("Please enter your password"),
  passwordMatch: Yup.string()
    .required("Please enter your matching password")
    .min(7, "Passwords must be 7 characters")
    .oneOf([Yup.ref("password"), null], "Passwords must match"),
});

const ChangePasswordPage = (props) => {
  const [cardClasses, setCardClasses] = React.useState("card-hidden");
  const history = useHistory();
  const [message, setMessage] = useState();
  const [user, setUser] = useState({});

  React.useEffect(() => {
    setTimeout(function () {
      setCardClasses("");
    }, 1000);
  });

  // extract the key from
  const { key } = querystring.parse(props.location.search.substring(1));

  useEffect(() => {
    async function checkValidity() {
      const vData = { key: key };
      const response = await fetch("/users/resetValid", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(vData),
      });

      const data = await response.json();

      let user = { status: response.status };

      if (response.status === 200) {
        user["userId"] = data.user._id;
      } else {
        user["msg"] = data.error;
      }
      setUser(user);
    }
    checkValidity(querystring.parse(props.location.search.substring(1)));
  }, [key, props.location.search]);

  async function onSubmit(data, e) {
    setMessage({
      data: "Password Change in progress is in progress...",
      type: "alert-warning",
    });

    const rData = {
      userId: user.userId,
      password: data.password,
    };

    const response = await fetch("/users/reset", {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(rData),
    });

    if (response.status === 200) {
      const res = await response.json();

      setMessage({
        data: "Welcome back " + res.user.first_name + "! redirecting....",
        type: "alert-success",
      });
      localStorage.setItem("token", res.token);
      localStorage.setItem("id", res.user._id);
      localStorage.setItem("first_name", res.user.first_name);
      localStorage.setItem("last_name", res.user.last_name);
      localStorage.setItem("access", res.user.access.join("|"));
      localStorage.setItem("role", res.user.role.toString());

      setTimeout(() => {
        history.push("/main/home");
      }, 2000);
      e.target.reset();
      return;
    }
    setMessage({
      data: "Invalid Login",
      type: "alert-danger",
    });
  }

  return (
    <>
      {user.status === 200 && (
        <Formik
          validationSchema={schema}
          initialValues={{
            email: "",
            password: "",
          }}
          onSubmit={(values) => onSubmit(values)}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <>
              <div
                className="full-page section-image"
                data-color="black"
                data-image={
                  require("../../assets/img/misc/building.jpg").default
                }
              >
                <div className="content d-flex align-items-center p-0">
                  <Container>
                    <Col className="mx-auto" lg="4" md="8">
                      {message && (
                        <div
                          className={`alert fade show d-flex ${message.type}`}
                          role="alert"
                        >
                          {message.data}
                          <span
                            aria-hidden="true"
                            className="ml-auto cursor-pointer"
                            onClick={() => setMessage(null)}
                          >
                            &times;
                          </span>
                        </div>
                      )}
                      <Form noValidate onSubmit={handleSubmit}>
                        <Card className={"card-login " + cardClasses}>
                          <Card.Header>
                            <h3 className="header text-center">
                              Enter New Password
                            </h3>
                          </Card.Header>
                          <Card.Body>
                            <Card.Body>
                              <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                  type="password"
                                  name="password"
                                  placeholder="Enter Password"
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isValid={touched.password && !errors.password}
                                />
                                {errors.password && touched.password ? (
                                  <div className="text-danger">
                                    {errors.password}
                                  </div>
                                ) : null}
                              </Form.Group>

                              <Form.Group>
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                  type="password"
                                  name="passwordMatch"
                                  placeholder="Enter Password (again)"
                                  value={values.passwordMatch}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  isValid={
                                    touched.passwordMatch &&
                                    !errors.passwordMatch
                                  }
                                />
                                {errors.passwordMatch &&
                                touched.passwordMatch ? (
                                  <div className="text-danger">
                                    {errors.passwordMatch}
                                  </div>
                                ) : null}
                              </Form.Group>
                            </Card.Body>
                          </Card.Body>
                          <Card.Footer className="ml-auto mr-auto">
                            <Button
                              className="btn-wd"
                              type="submit"
                              variant="warning"
                            >
                              Login
                            </Button>
                          </Card.Footer>
                        </Card>
                      </Form>
                    </Col>
                  </Container>
                </div>

                <div
                  className="full-page-background"
                  style={{
                    backgroundImage:
                      "url(" +
                      require("../../assets/img/misc/building.jpg").default +
                      ")",
                  }}
                />
              </div>
            </>
          )}
        </Formik>
      )}
      {user.status !== 200 && (
        <div
          className="full-page section-image"
          data-color="black"
          data-image={require("../../assets/img/misc/building.jpg").default}
        >
          <div className="content d-flex align-items-center p-0">
            <Container>
              <Col className="mx-auto" lg="4" md="8">
                <Card className="card-login">
                  <Card.Header>
                    <h3 className="header text-center">Link Has Expired</h3>
                  </Card.Header>
                  <Card.Body>
                    The link you clicked on in the email has expired or has
                    already been used. You can generate a new link by pressing
                    the Resend Button.
                  </Card.Body>
                  <Card.Footer className="ml-auto mr-auto">
                    <Button className="btn-wd" type="button" variant="warning">
                      <Link
                        style={{ color: "white" }}
                        to="/auth/forgot_password"
                      >
                        Resend
                      </Link>
                    </Button>
                  </Card.Footer>
                </Card>
              </Col>
            </Container>
          </div>
          <div
            className="full-page-background"
            style={{
              backgroundImage:
                "url(" +
                require("../../assets/img/misc/building.jpg").default +
                ")",
            }}
          />
        </div>
      )}
    </>
  ); // end of the return
};

export default ChangePasswordPage;
