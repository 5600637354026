// import React, {useState, useEffect} from 'react';
import React from "react";
import { observer } from "mobx-react-lite";
import { Container, Row } from "react-bootstrap";

import BlogItem from "./BlogItem";
import blogStore from "../../stores/blog";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

const Blog = observer(() => {
  const { blogData } = blogStore;
  const {
    hasPrevPage,
    prevPage,
    totalPages,
    hasNextPage,
    nextPage,
    docs = [],
  } = blogData;

  const handleNewest = () => {
    blogStore.setPageNumber(1);
  };

  const handleNewer = () => {
    if (hasPrevPage) {
      blogStore.setPageNumber(prevPage);
    }
  };
  const handleOldest = () => {
    blogStore.setPageNumber(totalPages);
  };

  const handleOlder = () => {
    if (hasNextPage) {
      blogStore.setPageNumber(nextPage);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    blogStore.setSearchTerm(blogStore.tmpSearchTerm);
    console.log(
      "submit",
      "tmpSearch",
      blogStore.tmpSearchTerm,
      "searchTerm",
      blogStore.searchTerm
    );
    blogStore.setTmpSearchTerm("");
  };

  const handleChange = (e) => {
    e.preventDefault();
    blogStore.setTmpSearchTerm(e.target.value);
    if (e.target.value.length === 0) {
      blogStore.setSearchTerm("");
    }
  };
  return (
    <Container fluid>
      <Row>
        <span className="col-4 text-left">OLDEST</span>

        <span className="col-4">
          <Form inline onSubmit={handleSubmit} onChange={handleChange}>
            <Form.Control
              id="blogSearch"
              type="text"
              placeholder="Enter Search Here"
            />
          </Form>
        </span>
        <span className="col-4 text-right">NEWEST</span>
      </Row>
      <Row>
        <span className="col-4 text-left">
          <Button
            variant="primary"
            size="sm"
            style={{ marginRight: "10px" }}
            onClick={handleOldest}
          >
            &lt;&lt;
          </Button>
          <Button
            variant="primary"
            size="sm"
            style={{ marginRight: "10px" }}
            onClick={handleOlder}
          >
            &lt;
          </Button>
        </span>

        <span className="col-4">{blogStore.getDisplayText}</span>

        <span className="col-4 text-right">
          <Button
            variant="primary"
            size="sm"
            style={{ marginRight: "10px", marginLeft: "10px" }}
            onClick={handleNewer}
          >
            &gt;
          </Button>

          <Button
            variant="primary"
            size="sm"
            style={{ marginRight: "10px" }}
            onClick={handleNewest}
          >
            &gt; &gt;
          </Button>
        </span>
      </Row>
      <Row>
        {docs.map((item) => {
          item["searchTerm"] = blogStore.searchTerm;
          return <BlogItem key={item._id} {...item}></BlogItem>;
        })}
      </Row>
    </Container>
  );
});

export default Blog;
