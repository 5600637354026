import React from "react";
import Card from "react-bootstrap/Card";
import moment from "moment"


const ElderCard = (elder) => {

  function getContactMonth(cMonth) {

    const numElders = 5;

    // get the current month and year
    const now = moment();  
    const nowStr = now.format("YYYY-MM");
    
    // get the baseMonth
    var baseMonth = moment(cMonth,"YYYY-MM");

    // if the baseMonth is less than the current month loop until base month = > current month
    
    while(baseMonth.format('YYYY-MM') < nowStr) {
      baseMonth.add(numElders,'months'); 
    }
   var outStr = "";
   if (baseMonth.format('YYYY-MM') === nowStr) outStr = "*"; 
   outStr += baseMonth.format("MMMM YYYY") + ', ';

   // get the next two months
   baseMonth.add(numElders,"months");
   outStr += baseMonth.format('MMMM YYYY') + ', ';

   baseMonth.add(numElders,"months");
   outStr += baseMonth.format('MMMM YYYY');

   return outStr;

  }
  return (
    <Card key={elder.id} border="secondary" className="shadow mb-3 bg-white rounded">
      <Card.Img src={"/uploaded_images/" + elder.image} />
      <Card.Body>
        <Card.Title>
          {elder.first_name} {elder.last_name}
        </Card.Title>
        <Card.Text>
          <b>EMAIL:</b> {elder.primary_email.email}
          <br />
          <b>Phone:</b>{" "}
          {"(" +
            elder.primary_phone.number.substring(0, 3) +
            ") " +
            elder.primary_phone.number.substring(3, 6) +
            " - " +
            elder.primary_phone.number.substring(6, 10)}
          <br />
          <b>Contact Months: </b>
          {getContactMonth(elder.staff.contact_months)}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default ElderCard;
