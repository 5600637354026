import React from "react";
import Card from "react-bootstrap/Card";

import poster from "../../assets/img/misc/LadiesDay2024.jpg";

const LadiesDay = () => {
  return (
    <Card border="secondary" className="shadow  mb-3 bg-white rounded">
      <Card.Img variant="top" src={poster} />
      <Card.Body>
        <Card.Title>Ladies Day 2024</Card.Title>
        <Card.Text>
          <b>Date:</b> Saturday, June 22, 2023
          <br />
          <b>Registration:</b> 8:15am - 9:30am
          <br />
          <b>RSVP is encouraged:</b> (256) 232-4705 or whobbsst@westhobbs.org
          <br />
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default LadiesDay;
