import React from 'react';

const ElderText = () => {

    return (
        <>
            <p>Currently, the West Hobbs Street Church of Christ is overseen by four elders. In Acts 20:28 Paul
                summarizes the role of an Elder in a local congregation when he wrote <i><b>“Therefore take heed to
                    yourselves and to all the flock, among which the Holy Spirit has made you overseers, to shepherd the
                    church of God which He purchased with His own blood.</b></i></p>
            <p>In 1 Tim 3:1-7 We can learn the qualifications one must have to server as an elder. <i><b>“This is a
                faithful saying: If a man desires the position of a bishop, he desires a good work. A bishop then must
                be blameless, the husband of one wife, temperate, sober-minded, of good behavior, hospitable, able to
                teach; not given to wine, not violent, not greedy for money, but gentle, not quarrelsome, not covetous;
                one who rules his own house well, having his children in submission with all reverence (for if a man
                does not know how to rule his own house, how will he take care of the church of God?); not a novice,
                lest being puffed up with pride he fall into the same condemnation as the devil. Moreover he must have a
                good testimony among those who are outside, lest he fall into reproach and the snare of the devil.”</b></i>
            </p>
        </>
    )
}

export default ElderText;