import React from "react";
import Card from "react-bootstrap/Card";

import communion from "../../assets/img/misc/communion.png";

const Communion = () => {
  return (
    <Card border="secondary" className="shadow  mb-3 bg-white rounded">
      <Card.Img style={{ height: "200px" }} variant="top" src={communion} />
      <Card.Body>
        <Card.Title>Communion</Card.Title>
        <Card.Text>
          <p>
            <b>Acts 20:7</b> - Now on the first day of the week, when the
            disciples came together to break bread, Paul, ready to depart the
            next day, spoke to them and continued his message until midnight.
          </p>
          <p>
            <b>1 Cor. 10:16</b> – The cup of blessing which we bless, is it not
            the communion of the blood of Christ? The bread which we break, is
            it not the communion of the body of Christ?
          </p>
          <p>
            As commanded by the Bible, our congregation observes the Lord’s
            Supper every Sunday to commemorate the death, burial and
            resurrection of our Savior, Jesus Christ.
          </p>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Communion;
