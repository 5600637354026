import MainPage from "./pages/main/MainPage";

import LoginPage from "./pages/auth/LoginPage";
import LogoutPage from "./pages/auth/LogoutPage";
import ForgotPasswordPage from "./pages/auth/ForgotPasswordPage";
import RegisterPage from "./pages/auth/RegisterPage";
import ChangePasswordPage from "./pages/auth/ChangePasswordPage";

import AdminPage from "./pages/admin/AdminPage";

let routes = [
  {
    page: "/home",
    layout: "/main",
    name: "Main",
    component: MainPage,
  },
  {
    page: "/login",
    layout: "/auth",
    name: "Login",
    component: LoginPage,
  },
  {
    page: "/logout",
    layout: "/auth",
    name: "Logout",
    component: LogoutPage,
  },
  {
    page: "/forgot_password",
    layout: "/auth",
    name: "Forgot Password",
    component: ForgotPasswordPage,
  },
  {
    page: "/register",
    layout: "/auth",
    name: "Register",
    component: RegisterPage,
  },
  {
    page: "/change_password",
    layout: "/auth",
    name: "Change Password",
    component: ChangePasswordPage,
  },
  {
    page: "/main",
    layout: "/admin",
    name: "Admin",
    component: AdminPage,
  },
];

export default routes;
