import React, { useState, useEffect } from "react";
import axios from "axios";
import blogStore from "../../stores/blog";

import { format } from "date-fns";
//import { utcToZonedTime, format } from "date-fns-tz";

// react-bootstrap components
import { Button } from "react-bootstrap";

// core components
import ReactTable from "components/ReactTable/ReactTable.js";
import BlogEditItem from "./BlogEditItem";

function BlogEdit() {
  console.log(blogStore.allBlogData[0]);
  const [docs, setDocs] = useState(
    blogStore.allBlogData.map((prop, key) => {
      return {
        id: key,
        publish: prop.publish,
        sticky: prop.sticky,
        expire_date: prop.expire_date,
        _id: prop._id,
        title: prop.title,
        body: prop.body,
        author: prop.author,
        //publish_date: prop.publish_date,

        publish_date: format(new Date(prop.publish_date), "EEE, MMM LL, yyyy"),

        //publish_date: format(new Date(parseISO(prop.publish_date)), "eeee"),
        // publish_date: new Intl.DateTimeFormat("en-US", {
        //   month: "long",
        //   day: "2-digit",
        //   year: "numeric",
        // }).format(new Date(prop.publish_date)),

        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              onClick={() => {
                let obj = docs.find((o) => o.id === key);
                //                alert("You've clicked EDIT button on " + obj._id);
                console.log("obj", obj);
                <BlogEditItem />;
              }}
              variant="warning"
              size="sm"
              className="text-warning btn-link edit"
            >
              <i className="fa fa-edit" />
            </Button>{" "}
          </div>
        ),
      };
    })
  );

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  return (
    <>
      <ReactTable
        data={docs}
        columns={[
          { Header: "_id", accessor: "_id" },
          { Header: "publish", accessor: "publish" },
          { Header: "sticky", accessor: "sticky" },
          { Header: "expire_date", accessor: "expire_date" },
          { Header: "body", accessor: "body" },

          {
            Header: "Date",
            accessor: "publish_date",
          },
          {
            Header: "Author",
            accessor: "author",
          },
          {
            Header: "Title",
            accessor: "title",
          },
          {
            Header: "Actions",
            accessor: "actions",
            sortable: false,
            filterable: false,
          },
        ]}
        initialState={{
          hiddenColumns: ["_id", "publish", "sticky", "expire_date", "body"],
        }}
        /*
              You can choose between primary-pagination, info-pagination, success-pagination, warning-pagination, danger-pagination or none - which will make the pagination buttons gray
            */
        className="-striped -highlight primary-pagination"
      />
    </>
  );
}

export default BlogEdit;
