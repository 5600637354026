import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Card, Button, Modal } from "react-bootstrap";
import ShortRow from "./ShortRow";
import LongRow from "./LongRow";
import summer_series_image from "../../assets/img/misc/SummerSeries.jpeg";

const { utcToZonedTime, format } = require("date-fns-tz");

export default function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modal, setModal] = useState(false);

  const dt = new Date();
  let year = format(new utcToZonedTime(dt, "America/Chicago"), "yyyy");
  let now = format(new utcToZonedTime(dt, "America/Chicago"), "yyyy-MM-dd");
  let displayCount = 0;
  let maxDisplay = 5;

  useEffect(() => {
    getData(year);
  }, []);

  async function getData(year) {
    await axios
      .get("/summerseries?year=" + year)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching data: ", error);
        setError(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  if (loading) return "Loading...";
  if (error) return "Error!";
  if (data.length === 0) {
    return <></>;
  }
  return (
    <>
      <Card border="secondary" className="shadow  mb-3 bg-white rounded">
        <Card.Img variant="top" src={summer_series_image} />
        <Card.Body>
          <Card.Title>Join Us at 7:00 p.m. Each Wednesday Night</Card.Title>
          <Card.Text>
            <Table className="table-hover table-striped w-full">
              {data.map((speaker) => {
                if (speaker.date >= now && displayCount < maxDisplay) {
                  displayCount++;
                  return <ShortRow key={speaker._id} {...speaker} />;
                }
              })}
            </Table>
          </Card.Text>
        </Card.Body>
        <Card.Footer className="justify-content-center">
          <hr />
          <Button onClick={() => setModal(!modal)} variant="info">
            Click Here For Full List of Speakers
          </Button>
        </Card.Footer>
      </Card>
      <Modal size="xl" onHide={() => setModal(!modal)} show={modal}>
        <Modal.Header className="justify-content-center">
          <h1>{year} Summer Series</h1>
        </Modal.Header>
        <Modal.Body>
          <Table className="table-hover table-striped w-full">
            {data.map((speaker) => {
              return <LongRow key={speaker._id} {...speaker} />;
            })}
          </Table>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            className="btn-simple"
            onClick={() => setModal(!modal)}
            variant="link"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
