import React from "react";
// react-bootstrap components
import { Container, Row, Col, Image } from "react-bootstrap";

// core components

//import LiveStreamEmbeded from "../../components/Cards/LiveStreamEmbeded";
import LiveStream from "../../components/Cards/LiveStream";
import Blog from "../../components/Blog/Blog";
//import NoMask from "../../components/Cards/NoMask";
import SummerSeries from "../../components/SummerSeries/SummerSeries";
import VBS from "../../components/Cards/VBS_2024";
import LadiesDay from "../../components/Cards/LadiesDay";
// import MeetingFall2021 from "../../components/Cards/MeetingFall2021";

import WorshipText from "../../assets/txt/WorshipText";
import Prayer from "../../components/Cards/Prayer";
import Communion from "../../components/Cards/Communion";
import Giving from "../../components/Cards/Giving";
import Preaching from "../../components/Cards/Preaching";
import Singing from "../../components/Cards/Singing";

import JamesonText from "../../assets/txt/JamesonText";
import JamesonHeadshot from "../../assets/img/preacher/steward_headshot.jpg";
import JamesonFamily from "../../assets/img/preacher/steward_family.jpg";

import Elders from "../../components/Elders/Elders";

const Main = () => {
  return (
    <>
      <Container fluid>
        <a id="top" />
        <Row>
          <Col className="col-12 col-md-3">
            <SummerSeries />
            <LiveStream />
          </Col>
          <Col className="col-12   col-md-6">
            <Blog />
          </Col>
          <Col classname="col-12 col-md-3">
            <VBS />
            <LadiesDay />
          </Col>
        </Row>
        <a id="ourworship" />
        <hr style={{ marginTop: "2em" }} />
        <Row className="page_section">
          <Col className="col-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
            <h1>What to Expect if You Worship With Us</h1>
          </Col>
        </Row>
        <Row className="page_section">
          <WorshipText />
        </Row>
        <Row>
          <Col className="col-1" />
          <Col className="col-12 col-sm-12 col-md-2">
            <Prayer />
          </Col>
          <Col className="col-12 col-sm-12 col-md-2">
            <Singing />
          </Col>
          <Col className="col-12 col-sm-12 col-md-2">
            <Communion />
          </Col>
          <Col className="col-12 col-sm-12 col-md-2">
            <Giving />
          </Col>
          <Col className="col-12 col-sm-12 col-md-2">
            <Preaching />
          </Col>
          <Col className="col-1" />
        </Row>
        <a id="pulpitminister" />
        <hr style={{ marginTop: "2em" }} />
        <Row className="page_section">
          <Col className="col-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
            <h1>Pulpit Minister - Jameson Steward</h1>
          </Col>
        </Row>
        <Row>
          <Col className="col-12 col-sm-12 col-md-3 col-lg-3">
            <Image src={JamesonHeadshot} fluid />
          </Col>
          <Col className="col-12 col-sm-12 col-md-5 col-lg-5">
            <JamesonText />
          </Col>
          <Col className="col-12 col-sm-12 col-md-4 col-lg-4">
            <Image src={JamesonFamily} fluid />
            <p>
              <b>left to right - Casen, Jameson, Nate, Ava Drew & Ashley </b>
            </p>
          </Col>
        </Row>

        <a id="ourelders" />
        <hr style={{ marginTop: "2em" }} />

        <Elders />
      </Container>
    </>
  );
};

export default Main;
