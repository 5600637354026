import React from "react";
// react-bootstrap components

// core components

const AdminPage = () => {
  return <h1>ADMIN PAGE</h1>;
};

export default AdminPage;
