import BlogEdit from "./components/Blog/BlogEdit";
import Test from "./components/Test/Test";

import UnderConstruction from "./components/UnderConstruction/UnderConstruction";

let adminStr = localStorage.getItem("access");
let access = [];
if (adminStr) {
  access = adminStr.split("|");
}

let routes_admin_all = [
  {
    page: "/blog",
    layout: "/admin",
    access: "blog",
    name: "Blog",
    component: BlogEdit,
  },
  {
    page: "/summerseries",
    layout: "/admin",
    access: "summerseries",
    name: "Summer Series",
    component: UnderConstruction,
  },
  {
    page: "/attendance",
    layout: "/admin",
    access: "attendance",
    name: "Attendance",
    component: UnderConstruction,
  },
  {
    page: "/backup",
    layout: "/admin",
    access: "backup",
    name: "Back Up",
    component: UnderConstruction,
  },
  {
    page: "/test",
    layout: "/admin",
    access: "test",
    name: "Test",
    component: Test,
  },
];

let routes_admin = [];

routes_admin_all.forEach((route) => {
  if (access.includes(route.access)) {
    routes_admin.push(route);
  }
});

export default routes_admin;
