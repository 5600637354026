import React from "react";
import { Switch, Route } from "react-router-dom";
// react-bootstrap components

// core components
import Sidebar from "components/Sidebar/Sidebar.js";
//import AdminNavbar from "components/Navbars/MainNavbar";
//import AdminFooter from "components/Footers/AdminFooter.js";
//import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

// dinamically create dashboard routes
import routes_admin from "routes_admin.js";

import image from "assets/img/misc/full-screen-image-2.jpg";

function Admin() {
  const [sidebarImage, setSidebarImage] = React.useState(image);
  const [sidebarBackground, setSidebarBackground] = React.useState("azure");

  const getRoutes = (routes_admin) => {
    return routes_admin.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.page}
            key={key}
            component={prop.component}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar
          routes={routes_admin}
          image={sidebarImage}
          background={sidebarBackground}
        />
        <div className="main-panel">
          <div className="content">
            <Switch>{getRoutes(routes_admin)}</Switch>
          </div>
          <div
            className="close-layer"
            onClick={() =>
              document.documentElement.classList.toggle("nav-open")
            }
          />
        </div>
      </div>
    </>
  );
}

export default Admin;
